import {LoomServicesConfig} from "./LoomServicesConfig"
import {Auth} from "loom-auth-service";

export class LoomServices {
  constructor() {
    console.log("loom services instance created");
  }
  private auth: Auth | undefined;
  private initialized = false;
  public async init(config: LoomServicesConfig) {
    console.log("auth initialized instance created");
    this.auth = new Auth();
    await this.auth.init(config.authConfig);
    this.initialized = true;
  }
  public get authService(): Auth {
    if (!this.initialized || !this.auth) {
      throw new Error("Loom Service not initialized")
    }
    return this.auth;
  }
}
